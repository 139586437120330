<template>
    <div class="loading-animation">
        <div class="spinner">
            <div class="double-bounce1"></div>
            <div class="double-bounce2"></div>
            <div class="double-bounce3"></div>
            <div class="double-bounce4"></div>
        </div>
    </div>
</template>
