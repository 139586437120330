<template>
    <div>
        <div v-cloak v-show="!loading && discountProductsLoaded" class="best-sellers-title clearance-corner-title">
            <a v-if="clearanceCornerUrl" :href="clearanceCornerLink"><img class="clearance-corner-logo cc-logo-widget" :src="clearanceCornerUrl" alt="Bargain Basement Logo"/></a>
            <span v-else>Bargain Basement</span>
        </div>
        <p v-cloak v-show="!loading && discountProductsLoaded" class="best-sellers-description text-center .bargain-basement-text">
            <br>Featured Bargain Basement Items<br>All Bargain Basement items are new, in stock &amp; shipped from our warehouse.
        </p>
        <div class="best-sellers clearance-corner-widget" v-cloak v-show="!loading && discountProductsLoaded">
            <ProductBlock :product="product" v-for="product in discountProducts" :key="product.slug" />
            <div class="product-block list" style="display: flex; align-items: center; justify-content: center; background: none">
                <a
                    type="button"
                    class="btn btn-secondary clearance-corner-widget-button"
                    style="white-space: normal;"
                    :href="clearanceCornerLink"
                >Click here to access Bargain Basement</a>
            </div>
        </div>
        <div v-cloak v-show="!loading && productsLoaded" class="best-sellers-title">
            Here’s your curated list of products
        </div>
        <div class="best-sellers" v-cloak v-show="!loading && productsLoaded">
            <ProductBlock :product="product" v-for="product in limitedProducts" :key="product.slug" />
        </div>
        <div class="row" v-cloak v-if="!loaded || loading">
            <div class="col-xs-12">
                <throbber></throbber>
            </div>
        </div>

    </div>
</template>

<script>
import Throbber from "../Throbber.vue";
import ProductBlock from "./ProductBlock.vue";

export default {
  name: "CuratedProducts",
  props: {
    clearanceCornerLink: String,
  },
  components: {
            'Throbber': Throbber,
            'ProductBlock': ProductBlock,
        },
        data() {
            return {
                spotlightProducts: [],
                discountProducts: [],
                loading: true,
                loaded: false  // loaded in full - shows the throbber at the bottom of the grid
            }
        },
        computed: {
            limitedProducts () {
                return this.spotlightProducts; //.slice(0, 8);
            },
            productsLoaded () {
                return this.spotlightProducts.length > 0;
            },
            discountProductsLoaded() {
                return this.discountProducts && this.discountProducts.length > 0;
            },
        },
        methods: {
            async retrieveProducts(page = 1) {
                let response = await wwwApi.collections.getDetail("current-customer", page);
                return response
            },
        },
        async created () {
            const discountProductsResponse = await wwwApi.discounts.getDetail('all-clearance-corner');
            this.discountProducts = discountProductsResponse;
            this.loading = false;
            this.loaded = true;

            const vueContainer = document.getElementById('vue-container');
            if (vueContainer) {
                this.clearanceCornerUrl = vueContainer.getAttribute('data-cc-logo-url');
            }

            let page = 1;

            // Kill the curated product list for the time being.
            while (false) {
                let response = await this.retrieveProducts(page);
                this.spotlightProducts = this.spotlightProducts.concat(response.products.results);
                this.loading = false;

                if (response.products.current_page === response.products.pages) {
                    this.loaded = true;
                    break;
                }
                page++;
            }
        }
}
</script>

<style scoped>

</style>
